/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::45944bee0e892c48bba65b33810e0acd>>
 * @codegenCommand yarn build tokens
 */
export default `
html[data-color-mode="light"][data-theme~="light:light-increased-contrast"],
html[data-color-mode="dark"][data-theme~="dark:light-increased-contrast"],
html[data-color-mode="light"][data-contrast-mode="more"][data-theme~="light:light"],
html[data-color-mode="dark"][data-contrast-mode="more"][data-theme~="dark:light"] {
  color-scheme: light;
  --ds-text: #091E42;
  --ds-text-accent-lime: #37471F;
  --ds-text-accent-lime-bolder: #28311B;
  --ds-text-accent-red: #5D1F1A;
  --ds-text-accent-red-bolder: #42221F;
  --ds-text-accent-orange: #702E00;
  --ds-text-accent-orange-bolder: #38291E;
  --ds-text-accent-yellow: #533F04;
  --ds-text-accent-yellow-bolder: #332E1B;
  --ds-text-accent-green: #164B35;
  --ds-text-accent-green-bolder: #1C3329;
  --ds-text-accent-teal: #164555;
  --ds-text-accent-teal-bolder: #1E3137;
  --ds-text-accent-blue: #09326C;
  --ds-text-accent-blue-bolder: #1C2B41;
  --ds-text-accent-purple: #352C63;
  --ds-text-accent-purple-bolder: #2B273F;
  --ds-text-accent-magenta: #50253F;
  --ds-text-accent-magenta-bolder: #3D2232;
  --ds-text-accent-gray: #2C3E5D;
  --ds-text-accent-gray-bolder: #172B4D;
  --ds-text-disabled: #091E424F;
  --ds-text-inverse: #FFFFFF;
  --ds-text-selected: #09326C;
  --ds-text-brand: #09326C;
  --ds-text-danger: #5D1F1A;
  --ds-text-warning: #702E00;
  --ds-text-warning-inverse: #FFFFFF;
  --ds-text-success: #164B35;
  --ds-text-discovery: #352C63;
  --ds-text-information: #09326C;
  --ds-text-subtlest: #2C3E5D;
  --ds-text-subtle: #172B4D;
  --ds-link: #09326C;
  --ds-link-pressed: #09326C;
  --ds-link-visited: #5E4DB2;
  --ds-link-visited-pressed: #352C63;
  --ds-icon: #172B4D;
  --ds-icon-accent-lime: #4C6B1F;
  --ds-icon-accent-red: #AE2E24;
  --ds-icon-accent-orange: #A54800;
  --ds-icon-accent-yellow: #7F5F01;
  --ds-icon-accent-green: #216E4E;
  --ds-icon-accent-teal: #206A83;
  --ds-icon-accent-blue: #0055CC;
  --ds-icon-accent-purple: #5E4DB2;
  --ds-icon-accent-magenta: #943D73;
  --ds-icon-accent-gray: #44546F;
  --ds-icon-disabled: #091E424F;
  --ds-icon-inverse: #FFFFFF;
  --ds-icon-selected: #0055CC;
  --ds-icon-brand: #0055CC;
  --ds-icon-danger: #C9372C;
  --ds-icon-warning: #A54800;
  --ds-icon-warning-inverse: #FFFFFF;
  --ds-icon-success: #216E4E;
  --ds-icon-discovery: #6E5DC6;
  --ds-icon-information: #0055CC;
  --ds-icon-subtle: #2C3E5D;
  --ds-border: #9BB4CA80;
  --ds-border-accent-lime: #4C6B1F;
  --ds-border-accent-red: #AE2E24;
  --ds-border-accent-orange: #A54800;
  --ds-border-accent-yellow: #7F5F01;
  --ds-border-accent-green: #216E4E;
  --ds-border-accent-teal: #206A83;
  --ds-border-accent-blue: #0055CC;
  --ds-border-accent-purple: #5E4DB2;
  --ds-border-accent-magenta: #943D73;
  --ds-border-accent-gray: #44546F;
  --ds-border-disabled: #091E4224;
  --ds-border-focused: #0055CC;
  --ds-border-input: #44546F;
  --ds-border-inverse: #FFFFFF;
  --ds-border-selected: #0055CC;
  --ds-border-brand: #0055CC;
  --ds-border-danger: #C9372C;
  --ds-border-warning: #A54800;
  --ds-border-success: #216E4E;
  --ds-border-discovery: #6E5DC6;
  --ds-border-information: #0055CC;
  --ds-border-bold: #44546F;
  --ds-background-accent-lime-subtlest: #EFFFD6;
  --ds-background-accent-lime-subtlest-hovered: #D3F1A7;
  --ds-background-accent-lime-subtlest-pressed: #B3DF72;
  --ds-background-accent-lime-subtler: #D3F1A7;
  --ds-background-accent-lime-subtler-hovered: #B3DF72;
  --ds-background-accent-lime-subtler-pressed: #94C748;
  --ds-background-accent-lime-subtle: #B3DF72;
  --ds-background-accent-lime-subtle-hovered: #D3F1A7;
  --ds-background-accent-lime-subtle-pressed: #EFFFD6;
  --ds-background-accent-lime-bolder: #37471F;
  --ds-background-accent-lime-bolder-hovered: #28311B;
  --ds-background-accent-lime-bolder-pressed: #233013;
  --ds-background-accent-red-subtlest: #FFECEB;
  --ds-background-accent-red-subtlest-hovered: #FFD5D2;
  --ds-background-accent-red-subtlest-pressed: #FD9891;
  --ds-background-accent-red-subtler: #FFD5D2;
  --ds-background-accent-red-subtler-hovered: #FD9891;
  --ds-background-accent-red-subtler-pressed: #F87168;
  --ds-background-accent-red-subtle: #fbb7ae;
  --ds-background-accent-red-subtle-hovered: #FFD5D2;
  --ds-background-accent-red-subtle-pressed: #FFECEB;
  --ds-background-accent-red-bolder: #5D1F1A;
  --ds-background-accent-red-bolder-hovered: #42221F;
  --ds-background-accent-red-bolder-pressed: #49120C;
  --ds-background-accent-orange-subtlest: #FFF3EB;
  --ds-background-accent-orange-subtlest-hovered: #FEDEC8;
  --ds-background-accent-orange-subtlest-pressed: #FEC195;
  --ds-background-accent-orange-subtler: #FEDEC8;
  --ds-background-accent-orange-subtler-hovered: #FEC195;
  --ds-background-accent-orange-subtler-pressed: #FEA362;
  --ds-background-accent-orange-subtle: #FEC195;
  --ds-background-accent-orange-subtle-hovered: #FEDEC8;
  --ds-background-accent-orange-subtle-pressed: #FFF3EB;
  --ds-background-accent-orange-bolder: #702E00;
  --ds-background-accent-orange-bolder-hovered: #38291E;
  --ds-background-accent-orange-bolder-pressed: #3E2108;
  --ds-background-accent-yellow-subtlest: #FFF7D6;
  --ds-background-accent-yellow-subtlest-hovered: #F8E6A0;
  --ds-background-accent-yellow-subtlest-pressed: #F5CD47;
  --ds-background-accent-yellow-subtler: #F8E6A0;
  --ds-background-accent-yellow-subtler-hovered: #F5CD47;
  --ds-background-accent-yellow-subtler-pressed: #E2B203;
  --ds-background-accent-yellow-subtle: #F5CD47;
  --ds-background-accent-yellow-subtle-hovered: #F8E6A0;
  --ds-background-accent-yellow-subtle-pressed: #FFF7D6;
  --ds-background-accent-yellow-bolder: #533F04;
  --ds-background-accent-yellow-bolder-hovered: #332E1B;
  --ds-background-accent-yellow-bolder-pressed: #342800;
  --ds-background-accent-green-subtlest: #DCFFF1;
  --ds-background-accent-green-subtlest-hovered: #BAF3DB;
  --ds-background-accent-green-subtlest-pressed: #7EE2B8;
  --ds-background-accent-green-subtler: #BAF3DB;
  --ds-background-accent-green-subtler-hovered: #7EE2B8;
  --ds-background-accent-green-subtler-pressed: #4BCE97;
  --ds-background-accent-green-subtle: #7EE2B8;
  --ds-background-accent-green-subtle-hovered: #BAF3DB;
  --ds-background-accent-green-subtle-pressed: #DCFFF1;
  --ds-background-accent-green-bolder: #164B35;
  --ds-background-accent-green-bolder-hovered: #1C3329;
  --ds-background-accent-green-bolder-pressed: #0F3324;
  --ds-background-accent-teal-subtlest: #E7F9FF;
  --ds-background-accent-teal-subtlest-hovered: #C6EDFB;
  --ds-background-accent-teal-subtlest-pressed: #9DD9EE;
  --ds-background-accent-teal-subtler: #C6EDFB;
  --ds-background-accent-teal-subtler-hovered: #9DD9EE;
  --ds-background-accent-teal-subtler-pressed: #6CC3E0;
  --ds-background-accent-teal-subtle: #9DD9EE;
  --ds-background-accent-teal-subtle-hovered: #C6EDFB;
  --ds-background-accent-teal-subtle-pressed: #E7F9FF;
  --ds-background-accent-teal-bolder: #164555;
  --ds-background-accent-teal-bolder-hovered: #1E3137;
  --ds-background-accent-teal-bolder-pressed: #103034;
  --ds-background-accent-blue-subtlest: #E9F2FF;
  --ds-background-accent-blue-subtlest-hovered: #CCE0FF;
  --ds-background-accent-blue-subtlest-pressed: #85B8FF;
  --ds-background-accent-blue-subtler: #CCE0FF;
  --ds-background-accent-blue-subtler-hovered: #85B8FF;
  --ds-background-accent-blue-subtler-pressed: #579DFF;
  --ds-background-accent-blue-subtle: #85B8FF;
  --ds-background-accent-blue-subtle-hovered: #CCE0FF;
  --ds-background-accent-blue-subtle-pressed: #E9F2FF;
  --ds-background-accent-blue-bolder: #09326C;
  --ds-background-accent-blue-bolder-hovered: #1C2B41;
  --ds-background-accent-blue-bolder-pressed: #022353;
  --ds-background-accent-purple-subtlest: #F3F0FF;
  --ds-background-accent-purple-subtlest-hovered: #DFD8FD;
  --ds-background-accent-purple-subtlest-pressed: #B8ACF6;
  --ds-background-accent-purple-subtler: #DFD8FD;
  --ds-background-accent-purple-subtler-hovered: #B8ACF6;
  --ds-background-accent-purple-subtler-pressed: #9F8FEF;
  --ds-background-accent-purple-subtle: #c3b9fa;
  --ds-background-accent-purple-subtle-hovered: #DFD8FD;
  --ds-background-accent-purple-subtle-pressed: #F3F0FF;
  --ds-background-accent-purple-bolder: #352C63;
  --ds-background-accent-purple-bolder-hovered: #2B273F;
  --ds-background-accent-purple-bolder-pressed: #211A47;
  --ds-background-accent-magenta-subtlest: #FFECF8;
  --ds-background-accent-magenta-subtlest-hovered: #FDD0EC;
  --ds-background-accent-magenta-subtlest-pressed: #F797D2;
  --ds-background-accent-magenta-subtler: #FDD0EC;
  --ds-background-accent-magenta-subtler-hovered: #F797D2;
  --ds-background-accent-magenta-subtler-pressed: #E774BB;
  --ds-background-accent-magenta-subtle: #f2a6d4;
  --ds-background-accent-magenta-subtle-hovered: #FDD0EC;
  --ds-background-accent-magenta-subtle-pressed: #FFECF8;
  --ds-background-accent-magenta-bolder: #50253F;
  --ds-background-accent-magenta-bolder-hovered: #3D2232;
  --ds-background-accent-magenta-bolder-pressed: #37172A;
  --ds-background-accent-gray-subtlest: #F1F2F4;
  --ds-background-accent-gray-subtlest-hovered: #DCDFE4;
  --ds-background-accent-gray-subtlest-pressed: #B3B9C4;
  --ds-background-accent-gray-subtler: #DCDFE4;
  --ds-background-accent-gray-subtler-hovered: #B3B9C4;
  --ds-background-accent-gray-subtler-pressed: #8590A2;
  --ds-background-accent-gray-subtle: #B3B9C4;
  --ds-background-accent-gray-subtle-hovered: #DCDFE4;
  --ds-background-accent-gray-subtle-pressed: #F1F2F4;
  --ds-background-accent-gray-bolder: #44546F;
  --ds-background-accent-gray-bolder-hovered: #2C3E5D;
  --ds-background-accent-gray-bolder-pressed: #172B4D;
  --ds-background-disabled: #091E4208;
  --ds-background-input: #FFFFFF;
  --ds-background-input-hovered: #F7F8F9;
  --ds-background-input-pressed: #FFFFFF;
  --ds-background-inverse-subtle: #00000029;
  --ds-background-inverse-subtle-hovered: #0000003D;
  --ds-background-inverse-subtle-pressed: #00000052;
  --ds-background-neutral: #091E420F;
  --ds-background-neutral-hovered: #091E4224;
  --ds-background-neutral-pressed: #091E424F;
  --ds-background-neutral-subtle: #00000000;
  --ds-background-neutral-subtle-hovered: #091E420F;
  --ds-background-neutral-subtle-pressed: #091E4224;
  --ds-background-neutral-bold: #44546F;
  --ds-background-neutral-bold-hovered: #2C3E5D;
  --ds-background-neutral-bold-pressed: #172B4D;
  --ds-background-selected: #E9F2FF;
  --ds-background-selected-hovered: #CCE0FF;
  --ds-background-selected-pressed: #85B8FF;
  --ds-background-selected-bold: #09326C;
  --ds-background-selected-bold-hovered: #1C2B41;
  --ds-background-selected-bold-pressed: #022353;
  --ds-background-brand-subtlest: #E9F2FF;
  --ds-background-brand-subtlest-hovered: #CCE0FF;
  --ds-background-brand-subtlest-pressed: #85B8FF;
  --ds-background-brand-bold: #09326C;
  --ds-background-brand-bold-hovered: #1C2B41;
  --ds-background-brand-bold-pressed: #022353;
  --ds-background-brand-boldest: #1C2B41;
  --ds-background-brand-boldest-hovered: #09326C;
  --ds-background-brand-boldest-pressed: #0055CC;
  --ds-background-danger: #FFECEB;
  --ds-background-danger-hovered: #FFD5D2;
  --ds-background-danger-pressed: #FD9891;
  --ds-background-danger-bold: #5D1F1A;
  --ds-background-danger-bold-hovered: #42221F;
  --ds-background-danger-bold-pressed: #49120C;
  --ds-background-warning: #FFF7D6;
  --ds-background-warning-hovered: #F8E6A0;
  --ds-background-warning-pressed: #F5CD47;
  --ds-background-warning-bold: #533F04;
  --ds-background-warning-bold-hovered: #332E1B;
  --ds-background-warning-bold-pressed: #342800;
  --ds-background-success: #DCFFF1;
  --ds-background-success-hovered: #BAF3DB;
  --ds-background-success-pressed: #7EE2B8;
  --ds-background-success-bold: #164B35;
  --ds-background-success-bold-hovered: #1C3329;
  --ds-background-success-bold-pressed: #0F3324;
  --ds-background-discovery: #F3F0FF;
  --ds-background-discovery-hovered: #DFD8FD;
  --ds-background-discovery-pressed: #B8ACF6;
  --ds-background-discovery-bold: #352C63;
  --ds-background-discovery-bold-hovered: #2B273F;
  --ds-background-discovery-bold-pressed: #211A47;
  --ds-background-information: #E9F2FF;
  --ds-background-information-hovered: #CCE0FF;
  --ds-background-information-pressed: #85B8FF;
  --ds-background-information-bold: #09326C;
  --ds-background-information-bold-hovered: #1C2B41;
  --ds-background-information-bold-pressed: #022353;
  --ds-blanket: #091E427D;
  --ds-blanket-selected: #388BFF14;
  --ds-blanket-danger: #EF5C4814;
  --ds-interaction-hovered: #00000029;
  --ds-interaction-pressed: #00000052;
  --ds-skeleton: #091E420F;
  --ds-skeleton-subtle: #091E4208;
  --ds-chart-categorical-1: #206A83;
  --ds-chart-categorical-1-hovered: #164555;
  --ds-chart-categorical-2: #5E4DB2;
  --ds-chart-categorical-2-hovered: #352C63;
  --ds-chart-categorical-3: #A54800;
  --ds-chart-categorical-3-hovered: #702E00;
  --ds-chart-categorical-4: #943D73;
  --ds-chart-categorical-4-hovered: #50253F;
  --ds-chart-categorical-5: #09326C;
  --ds-chart-categorical-5-hovered: #1C2B41;
  --ds-chart-categorical-6: #5E4DB2;
  --ds-chart-categorical-6-hovered: #352C63;
  --ds-chart-categorical-7: #50253F;
  --ds-chart-categorical-7-hovered: #3D2232;
  --ds-chart-categorical-8: #A54800;
  --ds-chart-categorical-8-hovered: #702E00;
  --ds-chart-lime-bold: #4C6B1F;
  --ds-chart-lime-bold-hovered: #37471F;
  --ds-chart-lime-bolder: #37471F;
  --ds-chart-lime-bolder-hovered: #28311B;
  --ds-chart-lime-boldest: #28311B;
  --ds-chart-lime-boldest-hovered: #233013;
  --ds-chart-neutral: #626F86;
  --ds-chart-neutral-hovered: #44546F;
  --ds-chart-red-bold: #AE2E24;
  --ds-chart-red-bold-hovered: #5D1F1A;
  --ds-chart-red-bolder: #5D1F1A;
  --ds-chart-red-bolder-hovered: #42221F;
  --ds-chart-red-boldest: #42221F;
  --ds-chart-red-boldest-hovered: #49120C;
  --ds-chart-orange-bold: #A54800;
  --ds-chart-orange-bold-hovered: #702E00;
  --ds-chart-orange-bolder: #702E00;
  --ds-chart-orange-bolder-hovered: #38291E;
  --ds-chart-orange-boldest: #38291E;
  --ds-chart-orange-boldest-hovered: #3E2108;
  --ds-chart-yellow-bold: #7F5F01;
  --ds-chart-yellow-bold-hovered: #533F04;
  --ds-chart-yellow-bolder: #533F04;
  --ds-chart-yellow-bolder-hovered: #332E1B;
  --ds-chart-yellow-boldest: #332E1B;
  --ds-chart-yellow-boldest-hovered: #342800;
  --ds-chart-green-bold: #216E4E;
  --ds-chart-green-bold-hovered: #164B35;
  --ds-chart-green-bolder: #164B35;
  --ds-chart-green-bolder-hovered: #1C3329;
  --ds-chart-green-boldest: #1C3329;
  --ds-chart-green-boldest-hovered: #0F3324;
  --ds-chart-teal-bold: #206A83;
  --ds-chart-teal-bold-hovered: #164555;
  --ds-chart-teal-bolder: #164555;
  --ds-chart-teal-bolder-hovered: #1E3137;
  --ds-chart-teal-boldest: #1E3137;
  --ds-chart-teal-boldest-hovered: #103034;
  --ds-chart-blue-bold: #0055CC;
  --ds-chart-blue-bold-hovered: #09326C;
  --ds-chart-blue-bolder: #09326C;
  --ds-chart-blue-bolder-hovered: #1C2B41;
  --ds-chart-blue-boldest: #1C2B41;
  --ds-chart-blue-boldest-hovered: #022353;
  --ds-chart-purple-bold: #5E4DB2;
  --ds-chart-purple-bold-hovered: #352C63;
  --ds-chart-purple-bolder: #352C63;
  --ds-chart-purple-bolder-hovered: #2B273F;
  --ds-chart-purple-boldest: #2B273F;
  --ds-chart-purple-boldest-hovered: #211A47;
  --ds-chart-magenta-bold: #943D73;
  --ds-chart-magenta-bold-hovered: #50253F;
  --ds-chart-magenta-bolder: #50253F;
  --ds-chart-magenta-bolder-hovered: #3D2232;
  --ds-chart-magenta-boldest: #3D2232;
  --ds-chart-magenta-boldest-hovered: #37172A;
  --ds-chart-gray-bold: #626F86;
  --ds-chart-gray-bold-hovered: #44546F;
  --ds-chart-gray-bolder: #44546F;
  --ds-chart-gray-bolder-hovered: #2C3E5D;
  --ds-chart-gray-boldest: #2C3E5D;
  --ds-chart-gray-boldest-hovered: #172B4D;
  --ds-chart-brand: #0C66E4;
  --ds-chart-brand-hovered: #0055CC;
  --ds-chart-danger: #C9372C;
  --ds-chart-danger-hovered: #AE2E24;
  --ds-chart-danger-bold: #5D1F1A;
  --ds-chart-danger-bold-hovered: #42221F;
  --ds-chart-warning: #7F5F01;
  --ds-chart-warning-hovered: #533F04;
  --ds-chart-warning-bold: #533F04;
  --ds-chart-warning-bold-hovered: #332E1B;
  --ds-chart-success: #216E4E;
  --ds-chart-success-hovered: #164B35;
  --ds-chart-success-bold: #164B35;
  --ds-chart-success-bold-hovered: #1C3329;
  --ds-chart-discovery: #6E5DC6;
  --ds-chart-discovery-hovered: #5E4DB2;
  --ds-chart-discovery-bold: #352C63;
  --ds-chart-discovery-bold-hovered: #2B273F;
  --ds-chart-information: #0C66E4;
  --ds-chart-information-hovered: #0055CC;
  --ds-chart-information-bold: #09326C;
  --ds-chart-information-bold-hovered: #1C2B41;
  --ds-surface: #FFFFFF;
  --ds-surface-hovered: #F1F2F4;
  --ds-surface-pressed: #DCDFE4;
  --ds-surface-overlay: #FFFFFF;
  --ds-surface-overlay-hovered: #F1F2F4;
  --ds-surface-overlay-pressed: #DCDFE4;
  --ds-surface-raised: #FFFFFF;
  --ds-surface-raised-hovered: #F1F2F4;
  --ds-surface-raised-pressed: #DCDFE4;
  --ds-surface-sunken: #FCFDFE;
  --ds-shadow-overflow: 0px 0px 8px #091E4229;
  --ds-shadow-overflow-perimeter: #091e421f;
  --ds-shadow-overflow-spread: #091e4229;
  --ds-shadow-overlay: inset 0px 0px 0px 1px #091E4280;
  --ds-shadow-raised: inset 0px 0px 0px 1px #091E4280;
  --ds-opacity-disabled: 0.4;
  --ds-opacity-loading: 0.2;
  --ds-UNSAFE-transparent: transparent;
  --ds-elevation-surface-current: #FFFFFF;
}
`;
